import { DTO } from './GeneralModels';
import { UserMinimalReadDTO } from './UserModels';
import { RestaurantNameReadDto } from './RestaurantModels';
import { DeliveryType } from './order/OrderModels';

export enum NTAK_CERT_STATUS {
	WAITING_CERT = 'WAITING_CERT',
	PENDING_VERIFICATION = 'PENDING_VERIFICATION',
	VERIFIED = 'VERIFIED',
	FAILED = 'FAILED',
}

export interface NTAKCertificateReadDto extends DTO {
	id: number;
	restaurant?: number;
	ntakRegistrationNumber?: string;
	vatNumber?: string;
	active?: boolean;
	expiry?: Date;
	verified?: Date;
	createdAt?: Date;
	status?: NTAK_CERT_STATUS;
	statusMessage?: string;
	createdBy?: UserMinimalReadDTO;
	lastModifiedAt?: Date;
	lastModifiedBy?: UserMinimalReadDTO;
}

export interface NTAKCsrReadDto extends DTO {
	csrFilename: string;
	csrValue: string;
}

export enum NtakOrderSource {
	TELEPHONES = 'TELEPHONES',
	WOLT = 'WOLT',
	FOODPANDA = 'FOODPANDA',
	FALATOZZ = 'FALATOZZ',
	EHENHALOK = 'EHENHALOK',
	PLUGIN = 'PLUGIN',
	WHITELABEL = 'WHITELABEL',
}

export enum NtakStatus {
	ORDER_IN_PROGRESS = 'ORDER_IN_PROGRESS',
	PENDING_REPORT = 'PENDING_REPORT',
	PROCESSING_REPORT = 'PROCESSING_REPORT',
	SUCCESSFUL = 'SUCCESSFUL',
	CANCEL_REPORTED = 'CANCEL_REPORTED',
	DAILY_REPORT_DONE = 'DAILY_REPORT_DONE',
	FAILED = 'FAILED',
	SKIPPED = 'SKIPPED',
}

export interface NTAKOrderHistoryEntryReadDto extends DTO {
	id: number;
	source: NtakOrderSource;
	type: DeliveryType;
	dailyOrderNumber: number;
	ntakStatus: NtakStatus;
	ntakStatusNote: string;
	ntakLocalId: string;
	ntakRemoteId: string;
	createdAt: Date;
}

export interface NTAKStatus {
	code?: string;
	orderItemId?: number;
	orderItemApiName?: string;
	menuItemId?: string;
	menuItemSkuId?: string;
	toppingOrderItemId?: string;
	toppingOrderItemApiName?: string;
	toppingId?: string;
	toppingSkuId?: string;
	//for calculation_error
	paidAmount?: number;
	grandTotal?: number;
	itemsTotal?: number;
	//for invalid_vat
	value?: number;
	//for unhandled_error:
	ntakCode?: any;
	field?: any;
	message?: any;
}
