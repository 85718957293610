import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { paginationPerPage } from '../utils/GlobalConstant';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';

export function useDateTableUrlHistory() {
  const queryParams = useSearchParams();

  const start = useMemo<string>(() => {
    return queryParams.get('start') || moment().format('YYYY-MM-DD');
  }, [queryParams]);

  const end = useMemo<string>(() => {
    return queryParams.get('end') || moment().format('YYYY-MM-DD');
  }, [queryParams]);

  const page = useMemo<number>(() => {
    return parseInt(queryParams.get('page') ?? '1');
  }, [queryParams]);

  const perPage = useMemo<number>(() => {
    return parseInt(queryParams.get('perPage') ?? `${paginationPerPage}`);
  }, [queryParams]);

  const sort = useMemo<string[]>(() => {
    return ((queryParams.get('sort') ?? '') as string).split(',');
  }, [queryParams]);

	return { start, end, page, perPage, sort };
}
