import { AddressDTO, DTO, PhoneDTO, SingleTextDTO } from './GeneralModels';
import { SystemRole } from './enum/SystemRole';
import { RestaurantRole } from './enum/RestaurantRole';
import { AcceptedTOSMinimalDTO } from './TermOfServiceModels';

export enum RegistrationStep {
	START = 'START',
	ACCEPT_TOS = 'ACCEPT_TOS',
	ADD_COMPANY_DATA = 'ADD_COMPANY_DATA',
	ADD_RESTAURANT = 'ADD_RESTAURANT',
	ADD_CREDIT_CARD = 'ADD_CREDIT_CARD',
	COMPLETE = 'COMPLETE',
}

export interface UserRestaurantMinimalReadDto extends DTO {
	userId: number;
	restaurantId: number;
	restaurantRole: RestaurantRole;
	primary: boolean;
}

export interface TosMinimalDTO extends DTO {
	acceptedDate: string;
	termsOfService: number;
}

export interface UserDetailedReadDTO extends DTO {
	id: number;
	email: string;
	username: string;
	firstName: string;
	lastName: string;
	enabled: boolean;
	attachedUserPrefix: string;
	billingAddressList: AddressDTO[];
	acceptedList: TosMinimalDTO[];
	phone?: PhoneDTO;
	role: SystemRole;
	restaurantList: UserRestaurantMinimalReadDto[];
}

export interface UserMinimalReadDTO extends DTO {
	id: number;
	username: string;
	firstName: string;
	lastName: string;
	enabled: boolean;
	createdAt: Date;
}

export interface UserAdminMinimalReadDTO extends DTO {
	id: number;
	username: string;
	firstName: string;
	lastName: string;
	role: SystemRole;
}

export interface UserAdminWriteDTO extends DTO {
	id?: number;
	username?: string;
	firstName?: string;
	lastName?: string;
	role?: any;
	email?: string;
	password?: string;
}

export interface UserWriteDTO extends DTO {
	id?: number;
	username?: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	enabled?: boolean;
	phone?: PhoneDTO;
	password?: string;
}

export interface UserStaffReadDTO extends DTO {
	id?: number;
	username: string;
	firstName: string;
	lastName: string;
	email?: string;
	password?: string;
	enabled: boolean;
	phone?: PhoneDTO;
	acceptedList?: AcceptedTOSMinimalDTO[];
	billingAddressList?: AddressDTO[];
}

export interface StaffListWrapper extends DTO {
	user: UserMinimalReadDTO;
	restaurantRole: string;
	lastActivity: Date;
}

export interface UserRestaurantStaffWrapper extends DTO {
	restaurantId: number;
	restaurantRole: RestaurantRole;
	primary: boolean;
	sequenceNumber?: number;
	pin?: number;
}

export interface StaffWriteWrapper extends DTO {
	user: UserWriteDTO;
	restaurants: UserRestaurantStaffWrapper[];
}
export interface StaffWrapper extends DTO {
	user: UserStaffReadDTO;
	restaurants: UserRestaurantStaffWrapper[];
}
