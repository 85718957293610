import {toast, ToastContent} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBoxArchive, faCheck, faExclamation, faInfo, faTimes, faTrashRestore} from "@fortawesome/free-solid-svg-icons";
import {ToastOptions} from "react-toastify/dist/types";

export function successToast(content: ToastContent, options?: ToastOptions<{}> | undefined) {
    toast.success(content, {
        ...options,
        icon: <FontAwesomeIcon icon={faCheck}/>
    });
}

export function infoToast(content: ToastContent, options?: ToastOptions<{}> | undefined) {
    toast.info(content, {
        ...options,
        icon: <FontAwesomeIcon icon={faInfo}/>
    });
}

export function warnToast(content: ToastContent, options?: ToastOptions<{}> | undefined) {
    toast.warn(content, {
        ...options,
        icon: <FontAwesomeIcon icon={faExclamation}/>
    });
}

export function errorToast(content: ToastContent, options?: ToastOptions<{}> | undefined) {
    toast.error(content, {
        ...options,
        icon: <FontAwesomeIcon icon={faTimes}/>
    });
}

export function resetToast(content: ToastContent, options?: ToastOptions<{}> | undefined) {
    toast.success(content, {
        ...options,
        icon: <FontAwesomeIcon icon={faTrashRestore}/>
    });
}

export function archiveToast(content: ToastContent, options?: ToastOptions<{}> | undefined) {
    toast.success(content, {
        ...options,
        icon: <FontAwesomeIcon icon={faBoxArchive}/>
    });
}
