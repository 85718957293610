import { useQuery } from '@tanstack/react-query';
import { apiGet, useOrsysContext } from '../../utils';
import type { UseQueryOptions } from '@tanstack/react-query/src/types';
import { PrepLocationDto } from '../../models';

export const prepLocationsGlobalKey = ['prepLocations'];

export const prepLocationsByRestaurantKey = (restaurantId: number) => ['prepLocations', restaurantId];

export function usePrepLocationsByRestaurantQuery(restaurantId: number, { options }: {
  options?: UseQueryOptions<PrepLocationDto[]>
} = {}) {
  const { user } = useOrsysContext();
  return useQuery({
    ...(options || {}),
    initialData: [],
    gcTime: 1000 * 60 * 5,
    queryKey: prepLocationsByRestaurantKey(restaurantId),
    queryFn: async () => {
      const { data: response } = await apiGet<PrepLocationDto[]>(`/prep-location/by-restaurant/${restaurantId}`);
      return response;
    },
    enabled: user?.id != null && restaurantId != null
  });
}

