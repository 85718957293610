import { RestaurantRole, SystemRole, UserDetailedReadDTO } from '@orsys/common';
import { OrsysContextProviderValues } from './OrsysContext';

export const isAdminOrManager = (user?: UserDetailedReadDTO | null) =>
	user && [SystemRole.ADMIN, SystemRole.SALES_MANAGER].includes(user?.role);

export const isOwner = (user?: UserDetailedReadDTO | null, restaurantIdToCheck?: number) =>
	user &&
	SystemRole.RESTAURANT_STAFF === user?.role &&
	(user?.restaurantList || []).find(({ restaurantId }) => restaurantId === restaurantIdToCheck)?.restaurantRole ===
		RestaurantRole.OWNER;

export const visibleByRoles: (
	orsysContext: OrsysContextProviderValues,
	systemRoles?: SystemRole[],
	restaurantRoles?: RestaurantRole[],
) => boolean = (orsysContext, systemRoles, restaurantRoles) => {
	const { user, restaurant } = orsysContext;
	if (user == null) {
		return false;
	}
	let isAvailable = true;

	if (systemRoles && systemRoles.length > 0 && !systemRoles.includes(user.role)) {
		isAvailable = false;
	}
	if (user.role === SystemRole.RESTAURANT_STAFF && restaurantRoles && restaurantRoles.length > 0) {
		if (restaurant == null) {
			return false;
		}
		let selectedRestaurant = null;
		let filteredRestaurant = user.restaurantList.filter(({ restaurantId }) => restaurant.id === restaurantId);
		if (filteredRestaurant.length > 0) {
			selectedRestaurant = filteredRestaurant[0];
		}
		if (
			selectedRestaurant &&
			restaurantRoles.length > 0 &&
			!restaurantRoles.includes(selectedRestaurant.restaurantRole)
		) {
			isAvailable = false;
		}
	} else if (restaurant == null && restaurantRoles && restaurantRoles.length > 0) {
		isAvailable = false;
	}

	return isAvailable;
};
