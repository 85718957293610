export const enum BarionStatus {
    Canceled = "Canceled",
    Deleted = "Deleted",
    Expired = "Expired",
    Failed = "Failed",
    InProgress = "InProgress",
    PartiallySucceeded = "PartiallySucceeded",
    Prepared = "Prepared",
    Reserved = "Reserved",
    Started = "Started",
    Succeeded = "Succeeded",
    InvalidRecurrenceId = "InvalidRecurrenceId"
}

