export const MODALCONTENT_MANAGE: string = 'manage';
export const MODALCONTENT_EDIT: string = 'edit';
export const SUCCESSFULL_TOAST_AUTOCLOSE: number = 3000;

export const CURRENCY_CONST: any = {
	HUF: 0,
	EUR: 2,
	USD: 2,
};

export const MAX_TITLE_LENGTH = 100;
export const MAX_DESCRIPTION_LENGTH = 500;

export const PASSWORD_PATTERN: RegExp = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{8,}$/;
export const USERNAME_PATTERN: RegExp = /^[A-Za-z0-9._+-]{3,25}$/;
export const URL_PATHNAME_PATTERN: RegExp = /^[A-Za-z0-9-]{3,120}$/;
export const RESTAURANT_SECRET_NAME_PATTERN: RegExp = /^[A-Za-z0-9.-]*$/;
export const EMAIL_PATTERN: RegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/;

export const TAB1: string = 'tab1';
export const TAB2: string = 'tab2';
export const TAB3: string = 'tab3';
export const TAB4: string = 'tab4';
export const TAB5: string = 'tab5';

export const PAYMENT_TYPE: any = {
	CASH: {
		textId: 'CASH',
		icon: 'faMoneyBillWave',
	},
	CASH_EUR: {
		textId: 'CASH_EUR',
		icon: 'faMoneyBillWave',
	},
	CASH_LARGE_BILLS: {
		textId: 'CASH_LARGE_BILLS',
		icon: 'faMoneyBillWave',
	},
	CREDIT_CARD_ONLINE: {
		textId: 'CREDIT_CARD_ONLINE',
		icon: 'faCreditCard',
	},
	CREDIT_CARD_TERMINAL: {
		textId: 'CREDIT_CARD_TERMINAL',
		icon: 'faCalculator',
	},
	SZEP_CARD_ONLINE: {
		textId: 'SZEP_CARD_ONLINE',
		icon: 'faCalculator',
	},
	SZEP_CARD_TERMINAL: {
		textId: 'SZEP_CARD_TERMINAL',
		icon: 'faCalculator',
	},
	VOUCHER: {
		textId: 'VOUCHER',
		icon: 'faMoneyCheckAlt',
	},
};

export const SUMMARY_TYPE: any = {
	DELIVERY: {
		textId: 'DELIVERY',
	},
	LOCAL: {
		textId: 'LOCAL',
	},
	STAFF: {
		textId: 'STAFF',
	},
	DISCOUNTED: {
		textId: 'DISCOUNTED',
	},
	CANCELLED: {
		textId: 'CANCELLED',
	},
	DINE_IN: {
		textId: 'DINE_IN',
	},
	TAKE_AWAY: {
		textId: 'TAKE_AWAY',
	},
	SERVICE_FEE: {
		textId: 'SERVICE_FEE',
	},
	TIP: {
		textId: 'TIP',
	},
	EXTRAS: {
		textId: 'EXTRAS',
	},
	COURIER_SALARIES: {
		textId: 'COURIER_SALARIES',
	},
};

export const RESTAURANT_OPTION_TYPE: any = {
	STRING: {
		textId: 'STRING',
	},
	INTEGER: {
		textId: 'INTEGER',
	},
	DOUBLE: {
		textId: 'DOUBLE',
	},
	SELECT: {
		textId: 'SELECT',
	},
	BOOLEAN: {
		textId: 'BOOLEAN',
	},
};

export const ORDER_NTAK_STATUS: any = {
	SUCCESSFUL: {
		textId: 'SUCCESSFUL',
		type: 'success',
	},
	FAILED: {
		textId: 'FAILED',
		type: 'danger',
	},
	PENDING_REPORT: {
		textId: 'PENDING_REPORT',
		type: 'info',
	},
	ORDER_IN_PROGRESS: {
		textId: 'ORDER_IN_PROGRESS',
		type: 'info',
	},
	PROCESSING_REPORT: {
		textId: 'PROCESSING_REPORT',
		type: 'info',
	},
	CANCEL_REPORTED: {
		textId: 'CANCEL_REPORTED',
		type: 'info',
	},
	DAILY_REPORT_DONE: {
		textId: 'DAILY_REPORT_DONE',
		type: 'info',
	},
	SKIPPED: {
		textId: 'SKIPPED',
		type: 'info',
	},
};

export const ORDER_STATUS: any = {
	SUCCESSFUL: {
		textId: 'SUCCESSFUL',
		type: 'success',
		icon: 'faCheck',
	},
	UNSUCCESSFUL: {
		textId: 'UNSUCCESSFUL',
		type: 'danger',
		icon: 'faTimes',
	},
	IN_PROGRESS: {
		textId: 'IN_PROGRESS',
		type: 'info',
		icon: 'faStopwatch',
	},
};

export const RESTAURANT_SETTLEMENT_TYPE: any = {
	DELIVERY: {
		textId: 'DELIVERY',
	},
	DINE_IN: {
		textId: 'DINE_IN',
	},
};

export const ORDER_SOURCE: any = {
	TELEPHONES: {
		textId: 'TELEPHONES',
		type: 'telephones',
		iconType: 'font',
		icon: 'faPhoneFlip',
		provider: '',
	},
	WOLT: {
		textId: 'WOLT',
		type: 'online',
		iconType: 'image',
		icon: 'wolt-logo.svg',
		provider: 'Wolt',
	},
	FOODPANDA: {
		textId: 'FOODPANDA',
		type: 'online',
		iconType: 'image',
		icon: 'foodpanda-logo.svg',
		provider: 'Foodpanda',
	},
	FALATOZZ: {
		textId: 'FALATOZZ',
		type: 'online',
		iconType: 'image',
		icon: 'falatozz-logo.svg',
		provider: 'Falatozz',
	},
	EHENHALOK: {
		textId: 'EHENHALOK',
		type: 'online',
		iconType: 'image',
		icon: 'ehenhalok-logo.svg',
		provider: 'Éhenhalok.hu',
	},
	PLUGIN: {
		textId: 'PLUGIN',
		type: 'online',
		iconType: 'image',
		icon: 'plugin-logo.svg',
		provider: 'Plugin',
	},
  WHITELABEL: {
		textId: 'WHITELABEL',
		type: 'online',
		iconType: 'image',
		icon: 'plugin-logo.svg',
		provider: 'Plugin',
	},
};

export const RegistrationStepHeaderKey = 'RegistrationStep';

export const paginationComponentOptions = {
	selectAllRowsItem: true,
	selectAllRowsItemText: 'Összes',
};

export const paginationPerPage = 25;

export const paginationRowsPerPageOptions = [25, 50, 100];

export const UNIT: string[] = ['KG', 'DKG', 'G', 'L', 'DL', 'CL', 'ML', 'PCS', 'PKG'];
