import { getNavigatorLanguage } from './GeneralUtils';
import { CURRENCY_CONST } from './GlobalConstant';

export function formatCurrency(data?: number, currency?: string | null, useGrouping?: string | null) {
	if (data !== undefined) {
		const localCurrency = currency ? currency : 'HUF';

		return new Intl.NumberFormat(currency === 'HUF' ? 'hu-HU' : getNavigatorLanguage(), {
			style: 'currency',
			currency: localCurrency,
			minimumFractionDigits: currency ? CURRENCY_CONST[currency] : 0,
			useGrouping: !(useGrouping && useGrouping === 'false'),
		}).format(localCurrency === 'HUF' ? Math.round(data) : data);
	} else {
		return '-';
	}
}

export function formatTime(data?: number) {
	if (data) {
		return new Intl.NumberFormat(getNavigatorLanguage(), {
			style: 'unit',
			unit: 'minute',
			unitDisplay: 'long',
		}).format(data);
	} else {
		return '-';
	}
}

export function formatNumber({
	data,
	maximumFractionDigits = 2,
	useGrouping = false,
}: {
	data: number;
	maximumFractionDigits?: number;
	useGrouping?: boolean;
}) {
	if (data) {
		return new Intl.NumberFormat('en', {
			style: 'decimal',
			maximumFractionDigits: maximumFractionDigits,
			useGrouping: useGrouping,
		}).format(data);
	} else {
		return '0';
	}
}

export function getDifference(a: number, b: number) {
	return a - b;
}

export function getPercentValue(baseValue: number, value: number) {
	// return  +Math.abs(100 - value / baseValue * 100).toFixed(1);
	let result = 0;
	if (baseValue === 0) {
		if (value === 0) {
			result = 0;
		} else if (value < 0) {
			result = -100;
		}
		result = 100;
	} else if (value === 0) {
		result = 0;
	} else {
		result = (value / baseValue) * 100;
	}
	return result.toFixed(1);
}

export function formatPercentage(value: number, decimals: number = 0) {
  return Intl.NumberFormat(getNavigatorLanguage(), {
    style: 'percent',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(value/100);
}

export function isInfiniteOrNaN(value: number) {
  return value === Infinity || isFinite(value) || isNaN(value);
}

export function getDifferencePercentValue(baseValue: number, value: number) {
	return ((value - baseValue) / baseValue) * 100;
}
