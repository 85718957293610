import moment from 'moment';
import { BarionStatus } from 'libs/common/src/models/enum/Barion';
import { RestaurantMinimalReadDTO } from 'libs/common/src/models/RestaurantModels';
import { BankCard } from 'libs/common/src/models/GeneralModels';

export function isExpiredCard(validityDate: Date) {
	return moment().isAfter(moment(validityDate));
}

export function barionShouldCheck(status: string) {
	return (
		!barionFailed(status) &&
		(status === BarionStatus.InProgress ||
			status === BarionStatus.Prepared ||
			status === BarionStatus.Started ||
			status === BarionStatus.Reserved)
	);
}

export function barionFailed(status: string) {
	return !(
		status === BarionStatus.Deleted ||
		status === BarionStatus.Canceled ||
		status === BarionStatus.Failed ||
		status === BarionStatus.Expired
	);
}

export function convertBankCardToSelect(bankcards: BankCard[]): any[] {
	let selectData: any[] = [];

	bankcards.forEach((value) => {
		selectData.push({
			label: `${value.type && value.type} **** ${value.lastDigits} (${value.validityMonth}/${value.validityYear})`,
			value: value.id,
		});
	});

	return selectData;
}
