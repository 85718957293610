import { RestaurantMinimalReadDTO } from 'libs/common/src/models/RestaurantModels';
import { RestaurantServiceType } from 'libs/common/src/models/enum/RestaurantServiceType';
import { Packages } from 'libs/common/src/models/enum/Subscription';

export const convertFromRestaurantMinimalReadToSelect: (restaurants: RestaurantMinimalReadDTO[]) => any[] = (
	restaurants,
) => {
	let selectData: any[] = [];

	restaurants &&
		restaurants.forEach((value) => {
			selectData.push({
				label: value.name?.text,
				value: value.id,
			});
		});

	return selectData;
};

export const restaurantHasCatering: (
	restaurant: RestaurantMinimalReadDTO | null,
	restaurantServiceType: RestaurantServiceType | null,
) => boolean = (restaurant, restaurantServiceType) => {
	return (
		(restaurantServiceType == RestaurantServiceType.DINE_IN ||
			restaurantServiceType == RestaurantServiceType.BOTH) &&
		restaurant?.activeSubscriptions?.find(
			(element) =>
				element == Packages.mini ||
				element == Packages.basic ||
				element == Packages.premium ||
				element == Packages.catering_package,
		) != null
	);
};

export const restaurantHasServiceType: (options: {
	restaurant: RestaurantMinimalReadDTO | null;
	restaurantServiceType: RestaurantServiceType | null;
	requiredServiceType?: RestaurantServiceType | null;
}) => boolean = ({ restaurant, restaurantServiceType, requiredServiceType }) => {
	switch (requiredServiceType) {
		case RestaurantServiceType.DINE_IN:
			return restaurantHasCatering(restaurant, restaurantServiceType);
		case RestaurantServiceType.DELIVERY:
			return restaurantHasDelivery(restaurant, restaurantServiceType);
		case RestaurantServiceType.BOTH:
			return (
				restaurantHasCatering(restaurant, restaurantServiceType) ||
				restaurantHasDelivery(restaurant, restaurantServiceType)
			);
	}
	return true;
};

export const restaurantHasDelivery: (
	restaurant: RestaurantMinimalReadDTO | null,
	restaurantServiceType: RestaurantServiceType | null,
) => boolean = (restaurant, restaurantServiceType) => {
	return (
		(restaurantServiceType == RestaurantServiceType.DELIVERY ||
			restaurantServiceType == RestaurantServiceType.BOTH) &&
		restaurant?.activeSubscriptions?.find(
			(element) =>
				element == Packages.basic || element == Packages.premium || element == Packages.delivery_package,
		) != null
	);
};

export const hasRequiredPackage: (
	restaurant: RestaurantMinimalReadDTO | null,
	relevantPackages?: Packages[] | string[],
) => boolean = (restaurant, relevantPackages) => {
	return (
		(relevantPackages || []).filter((relevantPackage) => {
			switch (relevantPackage) {
				case Packages.premium:
					return restaurant?.activeSubscriptions?.find((element) => element == Packages.premium) != null;
				case Packages.basic:
					return (
						restaurant?.activeSubscriptions?.find(
							(element) => element == Packages.premium || element == Packages.basic,
						) != null
					);
				case Packages.mini:
					return (
						restaurant?.activeSubscriptions?.find(
							(element) =>
								element == Packages.premium || element == Packages.basic || element == Packages.mini,
						) != null
					);
				default:
					return restaurant?.activeSubscriptions?.find((element) => element == relevantPackage) != null;
			}
		}).length > 0
	);
};

export const restaurantEChasRegister: (restaurant: RestaurantMinimalReadDTO | null) => boolean = (restaurant) => {
	return restaurant?.activeSubscriptions?.find((element) => element == Packages.e_cash_register) != null;
};
export const restaurantHasQRAddon: (restaurant: RestaurantMinimalReadDTO | null) => boolean = (restaurant) => {
	return restaurant?.activeSubscriptions?.find((element) => element == Packages.qr_addon) != null;
};
