import React, { useContext } from 'react';
import styles from './Alert.module.scss';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { ButtonType, ButtonVariant } from '../Button/Button';
import { injectIntl } from 'react-intl';
import { WithIntlComponentProps } from 'libs/common/src/utils/i18n';
import { AlertType, OrsysContext, OrsysContextProviderValues } from 'libs/common/src/utils/OrsysContext';

interface AlertProps extends WithIntlComponentProps {
	className?: string;
}

function Alert(props: AlertProps) {
	const { alert, hideAlert } = useContext<OrsysContextProviderValues>(OrsysContext);
	const { className, intl } = props;
	const onCancel = () => {
		if (alert && alert.onConfirm) {
			alert.onConfirm();
		}
		hideAlert();
	};
	const onConfirm = () => {
		if (alert && alert.onConfirm) {
			alert.onConfirm(true);
		}
		hideAlert();
	};
	return (
		<div className={`${styles.alertContainer} ${alert ? styles.open : ''} ${className ? className : ''}`}>
			<div
				className={`${styles.backdrop} ${styles.closeEnabled}`}
				onClick={onCancel}
			/>
			<div className={styles.modal}>
				<div className={`${styles.titleContainer} ${styles.withBorderBottom}`}>
					<div className={styles.title}>{alert?.title}</div>
					<button
						className={styles.closeButton}
						onClick={onCancel}>
						<FontAwesomeIcon icon={faTimes} />
					</button>
				</div>
				<div className={styles.content}>{alert?.description}</div>
				<div className={styles.footer}>
					{alert?.type === AlertType.confirm && (
						<Button
							variant={ButtonVariant.primary}
							type={ButtonType.reverted}
							onClick={onCancel}>
							{alert?.cancelLabel ? alert?.cancelLabel : intl.formatMessage({ id: 'button.cancel' })}
						</Button>
					)}
					<Button
						variant={alert?.type === AlertType.confirm ? ButtonVariant.danger : ButtonVariant.primary}
						onClick={onConfirm}>
						{alert?.confirmLabel
							? alert?.confirmLabel
							: intl.formatMessage({
									id: alert?.type === AlertType.confirm ? 'alert.confirm' : 'alert.ok',
								})}
					</Button>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Alert);
