import { apiGet } from '../../utils/ApiUtils';
import { AttachmentReadDTO } from '@orsys/common';
import { useQuery } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query/src/types';

export const attachmentByRestaurantKey = (restaurantId?: number) => ['attachments', 'by-restaurant', restaurantId];

export function useAttachmentByRestaurantQuery(restaurantId?: number, { options }: {
	options?: UseQueryOptions<AttachmentReadDTO[]>
} = {}) {
	return useQuery({
		...(options || {}),
		initialData: [],
		gcTime: 1000 * 60 * 5,
		queryKey: attachmentByRestaurantKey(restaurantId),
		queryFn: async () => {
			const { data: response } = await apiGet<AttachmentReadDTO[]>(`/attachment/by-restaurant/${restaurantId}`);
			return response;
		},
		enabled: !!restaurantId
	});
}

