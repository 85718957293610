import {IntlShape, WrappedComponentProps} from "react-intl";

export interface WithIntlComponentProps extends WrappedComponentProps {
    intl: IntlShape
    locale: string
    messages: {
        [key: string]: any
    }

    [key: string]: any
}

export const getTranslations = (locale: string = "hu") => {
    return convertNestedFields(require(`../../translations/${locale}/general.json`));
};

const convertNestedFields = (messages: object) => {
    let result: {
        [key: string]: string
    } = {};

    function assignFields(messageObject: {
        [key: string]: any
    }, prefix = "") {
        Object.keys(messageObject).forEach((key)=>{
            const field = messageObject[key];
            if (typeof field === "string") {
                result[`${prefix}${key}`] = field;
            } else {
                assignFields(field, `${prefix}${key}.`);
            }
        });
    }

    assignFields(messages);

    return result;
}