import { apiGet } from '../../utils/ApiUtils';
import { MenuItemGroupMinimalReadDTO, MenuItemWriteDTO, SkuIdWrapperDTO, ToppingGroupMinimalReadDTO } from '@orsys/common';
import { useQuery } from '@tanstack/react-query';
import { useOrsysContext } from '../../utils/OrsysContext';
import type { UseQueryOptions } from '@tanstack/react-query/src/types';

export const toppingGlobalKey = ['toppings'];

export const useToppingGroupsByMenuIdKey = (menuId: number | string) => ['toppings', 'group', 'by-menu-id', `${menuId}`];

export function useToppingGroupsByMenuIdQuery(
	menuId: number | string,
	{
		options,
	}: {
		options?: UseQueryOptions<ToppingGroupMinimalReadDTO[]>;
	} = {},
) {
	const { user } = useOrsysContext();
	return useQuery({
		...(options || {}),
		initialData: [],
		gcTime: 1000 * 60 * 5,
		queryKey: useToppingGroupsByMenuIdKey(menuId),
		queryFn: async () => {
			const { data: response } = await apiGet<ToppingGroupMinimalReadDTO[]>(`/topping/group/by-menu/${menuId}/enabled`);
			return response;
		},
		enabled: user?.id != null && !!menuId,
	});
}
