export const enum CardDataType {
    Unknown = "Unknown",
    MasterCard = "MasterCard",
    Visa = "Visa",
    AmericanExpress = "AmericanExpress",
    Electron = "Electron",
    Maestro = "Maestro",
    DinersClub = "DinersClub",
    Discover = "Discover"
}
