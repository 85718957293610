import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { faCheck, faMinus, faTimes, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { ORDER_NTAK_STATUS, ORDER_SOURCE } from './GlobalConstant';
import Image from 'next/image';
import React from 'react';
import { IntlShape } from 'react-intl';

export function getSourceIcon(orderSource?: string) {
  if (orderSource) {
    console.log(orderSource,"orderSource")
    const source = ORDER_SOURCE[orderSource];

    if (source?.iconType === 'image') {
      return (
        <Image src={`/icons/api/${source.icon}`} alt={''} width={20} height={20} />
      );
    } else {
      const icon: 'faPhoneFlip' = source.icon;

      return <FontAwesomeIcon icon={icons[icon]} className={'sourceIcon'} size={'2x'} />;
    }
  } else {
    return <FontAwesomeIcon icon={faUtensils}
                            className={'sourceIcon'} />;
  }

  return <></>;
}

export function getNTAKIcon(intl: IntlShape, ntakStatus?: string) {
  switch (ntakStatus) {
    case ORDER_NTAK_STATUS.SUCCESSFUL.textId:
      return (<FontAwesomeIcon icon={faCheck} className={'green'}
                               title={intl.formatMessage({ id: `ntak.status.${ntakStatus}` })} />);
    case ORDER_NTAK_STATUS.FAILED.textId:
      return (<FontAwesomeIcon icon={faTimes} className={'red'}
                               title={intl.formatMessage({ id: `ntak.status.${ntakStatus}` })} />);
    case ORDER_NTAK_STATUS.PENDING_REPORT.textId:
    default:
      return (<FontAwesomeIcon icon={faMinus} title={intl.formatMessage({ id: 'ntak.status.WAITING_SEND' })} />);
  }
}

