export const PublicRuntimeConfig: {
	environment: string;
	googleMapsApiKey: string;
	wishlistApiUrl: string;
	wishlistApiKey: string;
	bugsnagApiKey: string;
	hotjarSiteId: number;
	backend: string;
	staticFiles: string;
	baseUrl: string;
	version: string;
} = {
	environment: process.env.NEXT_PUBLIC_ENVIRONMENT!,
	googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY!,
	wishlistApiUrl: process.env.NEXT_PUBLIC_WISHLIST_API_URL!,
	wishlistApiKey: process.env.NEXT_PUBLIC_WISHLIST_API_KEY!,
	bugsnagApiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY!,
	hotjarSiteId: parseInt(process.env.NEXT_PUBLIC_HOTJAR_SITE_ID!),
	backend: process.env.NEXT_PUBLIC_API!,
	staticFiles: process.env.NEXT_PUBLIC_STATIC_URL!,
	baseUrl: process.env.NEXT_PUBLIC_DOMAIN!,
	version: process.env.NEXT_PUBLIC_VERSION!,
};
