export const enum DeliveryOrderStatus {
    SCHEDULED = "SCHEDULED",
    PENDING = "PENDING",
    KITCHEN = "KITCHEN",
    WAITING_FOR_PICKUP = "WAITING_FOR_PICKUP",
    WAITING_FOR_COURIER = "WAITING_FOR_COURIER",
    COURIER_ASSIGNED = "COURIER_ASSIGNED",
    COURIER_STARTED = "COURIER_STARTED",
    COMPLETE = "COMPLETE",
    CANCEL = "CANCEL"
}

export const enum LocalOrderStatus {
    RESERVED="RESERVED",
    IN_PROGRESS="IN_PROGRESS",
    OVERTIME="OVERTIME",
    PENDING_PAYMENT="PENDING_PAYMENT",
    COMPLETE="COMPLETE",
    CANCEL="CANCEL"
}

//@TODO REFACT THIS
export const enum OrderStatus {
    SUCCESSFUL = "SUCCESSFUL",
    UNSUCCESSFUL = "UNSUCCESSFUL",
    IN_PROGRESS = "IN_PROGRESS",
}


export const enum OrderSourceMode {
    TEST = "TEST",
    LIVE = "LIVE",
    STOP = "STOP"
}

export enum OrderChangeType {
    ADD_ITEM = "ADD_ITEM",
    REMOVE_ITEM = "REMOVE_ITEM",
    CHANGE_ITEM = "CHANGE_ITEM",
    CUSTOMER = "CUSTOMER",
    ADDRESS = "ADDRESS",
    ZONE = "ZONE",
    PAYMENT_TYPE = "PAYMENT_TYPE",
    ADD_PAYMENT = "ADD_PAYMENT",
    REMOVE_PAYMENT = "REMOVE_PAYMENT",
    PRINT = "PRINT",
    SERVICE_FEE = "SERVICE_FEE",
    CLOSE_TABLE = "CLOSE_TABLE",
    OPEN_TABLE = "OPEN_TABLE",
    DELIVERY_TYPE = "DELIVERY_TYPE",
}
