import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import styles from './Modal.module.scss';
import navStyles from '../../navigation/WithNavigation/WithNavigation.module.scss';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ModalProps extends PropsWithChildren {
  title?: string | React.ReactNode;
  borderBottom?: boolean;
  open: boolean;
  onClose?: () => void;
  closeButton?: boolean;
  className?: string;
}

const Modal: FunctionComponent<ModalProps> = (props) => {
  const { title, children, borderBottom, open, onClose, closeButton, className } = props;

  useEffect(() => {
    try {
      let mainContent = document.querySelector(`.${navStyles.mainContent}`);
      if (mainContent) {
        if (open) {
          mainContent.classList.add('scrollDisabled');
        } else {
          mainContent.classList.remove('scrollDisabled');
        }
      }
    } catch (e) {
    }
  }, [open]);

  useEffect(() => {
    return () => {
      try {
        let mainContent = document.querySelector(`.${navStyles.mainContent}`);
        if (mainContent) {
          mainContent.classList.remove('scrollDisabled');
        }
      } catch (e) {
      }
    };
  }, []);

  return (
    <div className={`${styles.modalContainer} ${open ? styles.open : ''} ${className ? className : ''}`}>
      <div
        className={`${styles.backdrop} ${onClose ? styles.closeEnabled : ''}`}
        onClick={onClose}
      />
      <div className={styles.modal}>
        <div className={`${styles.titleContainer} ${borderBottom ? styles.withBorderBottom : ''}`}>
          <div className={styles.title}>{title && title}</div>
          {closeButton && (
            <button
              type={'button'}
              className={styles.closeButton}
              onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
