import { FC, PropsWithChildren } from 'react';
import { PublicRuntimeConfig } from '../config/PublicRuntimeConfig';

const { environment } = PublicRuntimeConfig;

export const DevContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      {environment != 'prod' && children}
    </>
  );
};
