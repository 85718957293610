import React, {PureComponent} from "react";
import Head from "next/head";

interface BrowserHeaderProps {
    title?: string
}

class BrowserHeader extends PureComponent<BrowserHeaderProps> {

    render() {
        const {title} = this.props;

        return (
            <Head>
                <title>{title?`${title} - Orsys Admin`:`Orsys Admin`}</title>
            </Head>
        );
    }
}

export default BrowserHeader;
