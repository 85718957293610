import React, { CSSProperties, FunctionComponent, MouseEventHandler, PropsWithChildren, useState } from 'react';
import styles from './Button.module.scss';

export const enum ButtonSize {
	medium = 'medium',
	small = 'small',
	smaller = 'smaller',
	tiny = 'tiny',
}

export const enum ButtonVariant {
	primary = 'primary',
	warning = 'warning',
	grey = 'grey',
	inactive = 'inactive',
	success = 'success',
	danger = 'danger',
	notify = 'notify',
	eyes = 'eyes',
}

export const enum ButtonType {
	simple = 'simple',
	reverted = 'reverted',
	outlined = 'outlined',
}

interface ButtonProps extends PropsWithChildren {
	active?: boolean;
	className?: string;
	size?: ButtonSize;
	variant?: ButtonVariant;
	type?: ButtonType;
	disabled?: boolean;
	icon?: boolean;
	href?: string;
	title?: string;
	onClick?: MouseEventHandler<HTMLButtonElement> | MouseEventHandler<HTMLAnchorElement> | undefined;
	formType?: 'submit' | 'reset' | 'button' | undefined;
	style?: CSSProperties | undefined;
}

const Button: FunctionComponent<ButtonProps> = ({
	formType,
	className,
	children,
	size,
	type,
	variant,
	disabled,
	icon,
	onClick,
	href,
	title,
	active = true,
	style,
}) => {
	const sizeClass = size ? size.toString() : ButtonSize.medium.toString();
	const variantClass = variant ? variant.toString() : ButtonVariant.primary.toString();
	const typeClass = type ? type.toString() : ButtonType.simple.toString();
	if (href) {
		return (
			<a
				onClick={onClick as MouseEventHandler<HTMLAnchorElement>}
				href={disabled ? undefined : href}
				className={`${styles.button} ${icon ? styles.icon : ''} ${styles[sizeClass]} ${styles[variantClass]} ${styles[typeClass]} ${
					!active ? styles.textOnly : ''
				} ${className ? className : ''}`}>
				{children}
			</a>
		);
	}
	return (
		<button
			disabled={disabled}
			type={formType}
			style={style}
			title={title}
			onClick={onClick as MouseEventHandler<HTMLButtonElement>}
			className={`${styles.button} ${icon ? styles.icon : ''} ${styles[sizeClass]} ${styles[variantClass]} ${styles[typeClass]} ${
				!active ? styles.textOnly : ''
			} ${className ? className : ''}`}>
			{children}
		</button>
	);
};

export default Button;
