import { apiGet } from '../../utils/ApiUtils';
import {
  convertFromDtoToJson,
  MenuItemGroupMinimalReadDTO,
  MenuItemWriteDTO,
  PrepLocationDto,
  SkuIdWrapperDTO
} from '@orsys/common';
import { useQuery } from '@tanstack/react-query';
import { useOrsysContext } from '../../utils/OrsysContext';
import type { UseQueryOptions } from '@tanstack/react-query/src/types';

export const menuItemGlobalKey = ['menu-items'];

export const menuItemGroupByIdKey = (groupId: number | string) => ['menu-items', 'group', 'by-id', `${groupId}`];

export function useMenuItemGroupByIdQuery(
	groupId: number | string,
	{
		options,
	}: {
		options?: UseQueryOptions<MenuItemGroupMinimalReadDTO>;
	} = {},
) {
	const { user } = useOrsysContext();
	return useQuery({
		...(options || {}),
		gcTime: 1000 * 60 * 5,
		queryKey: menuItemGroupByIdKey(groupId),
		queryFn: async () => {
			const { data: response } = await apiGet<MenuItemGroupMinimalReadDTO>(`/menu-item/group/${groupId}`);
			return response;
		},
		enabled: user?.id != null && !!groupId,
	});
}

export const menuItemNextSKUIDByGroupIdKey = (groupId: number | string) => ['menu-items', 'group', 'by-id', 'skuid', `${groupId}`];

export function useMenuItemNextSKUIDByGroupIdQuery(
	groupId: number | string,
	{
		options,
	}: {
		options?: Omit<UseQueryOptions<SkuIdWrapperDTO>, 'queryKey'>;
	} = {},
) {
	return useQuery({
		...(options || {}),
		gcTime: 0,
		queryKey: menuItemNextSKUIDByGroupIdKey(groupId),
		queryFn: async () => {
			const { data: response } = await apiGet<SkuIdWrapperDTO>(`/menu-item/group/${groupId}/next-skuid`);
			return response;
		},
	});
}

export const menuItemByIdKey = (id: number | string) => ['menu-items', 'by-id', `${id}`];

export function useMenuItemByIdQuery(
	id: number | string,
	groupId: number | string,
	{
		options,
	}: {
		options?: Omit<UseQueryOptions<MenuItemWriteDTO>, 'queryKey'>;
	} = {},
) {
	const { user } = useOrsysContext();
	return useQuery({
		...(options || {}),
		gcTime: 0,
		queryKey: menuItemByIdKey(id),
		queryFn: async () => {
			const { data: response } = await apiGet<MenuItemWriteDTO>(`/menu-item/${id}`);
			response.allergens = convertFromDtoToJson(response.allergens);
			return response;
		},
		enabled: user?.id != null && !!id && id !== 'new',
		initialData: {
			skuId: '',
			name: {
				text: '',
			},
			description: {
				text: '',
			},
			sortingNumber: 1000,
			menuItemGroupId: parseInt(groupId as string),
			toppingGroupIds: [],
      applicableToppingGroups: [],
			toppingGroups: [],
			mainImage: null,
			enabled: true,
			deliveryEnabled: true,
			price: '',
			discountPrice: '',
			packagingFee: '',
			vatPercentDineIn: '',
			vatPercentTakeaway: '',
			allergens: {
				1: false,
				2: false,
				3: false,
				4: false,
				5: false,
				6: false,
				7: false,
				8: false,
				9: false,
				10: false,
				11: false,
				12: false,
				13: false,
				14: false,
			},
			nutrients: {
				energy: '',
				fat: '',
				saturates: '',
				carb: '',
				sugar: '',
				protein: '',
				salt: '',
				monounsaturates: '',
				polyunsaturates: '',
				polyol: '',
				starch: '',
				fiber: '',
				energyKj: '',
			},
      prepLocationIds: [],
      prepLocations: [],
      applicablePrepLocations: [],
		},
	});
}
