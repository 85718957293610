import { AddressDTO } from 'libs/common/src/models/GeneralModels';
import { DeliveryOrderDetailedReadDTO, DeliveryOrderMinimalReadDTO } from 'libs/common/src/models/order/OrderModels';

export function getAddressToDeliveryOrder(data?: DeliveryOrderDetailedReadDTO | DeliveryOrderMinimalReadDTO, hideCountry = true) {
	return getAddress(data?.deliveryAddress, hideCountry);
}

export function getAddress(data?: AddressDTO, hideCountry = true) {
	let address: String[] = [];
	if (data) {
		if (data?.zip) {
			address.push(data.zip);
		}

		if (data?.country && !hideCountry) {
			address.push(`${data.country},`);
		}

		if (data?.city) {
			address.push(`${data.city},`);
		}

		if (data?.address) {
			address.push(data.address.replace(/sgt/g, 'sugárút'));
		}

		if (data?.number) {
			address.push(data.number);
		}
	}
	return address.join(' ');
}
