import {
  CustomerDetailedReadDto,
  DeliveryOrderMinimalReadDTO,
  PageAble,
  PaginationModel,
  ToppingGroupMinimalReadDTO
} from '@orsys/common';
import { useQuery } from '@tanstack/react-query';
import { useOrsysContext, apiGet } from '../../utils';
import type { UseQueryOptions } from '@tanstack/react-query/src/types';

export const customerGlobalKey = ['customers'];

export const useCustomerByIdKey = (customerId: number | string) => ['customers', 'by-id', `${customerId}`];

export function useCustomerByIdQuery(
  customerId: number | string,
  {
    options
  }: {
    options?: UseQueryOptions<CustomerDetailedReadDto>;
  } = {}
) {
  const { user } = useOrsysContext();
  return useQuery({
    ...(options || {}),
    gcTime: 1000 * 60 * 5,
    queryKey: useCustomerByIdKey(customerId),
    queryFn: async () => {
      const { data: response } = await apiGet<CustomerDetailedReadDto>(`/customer/${customerId}`);
      return response;
    },
    enabled: user?.id != null && !!customerId
  });
}

export const useCustomerDeliveryOrdersByIdKey = (customerId: number | string, pageable: PageAble) => ['customers', 'orders', 'delivery', `${customerId}`, pageable.page, pageable.size, pageable.sort];

export function useCustomerDeliveryOrdersByIdQuery(
  customerId: number | string,
  pageable: PageAble,
  {
    options
  }: {
    options?: UseQueryOptions<PaginationModel<DeliveryOrderMinimalReadDTO>>;
  } = {}
) {
  const { user } = useOrsysContext();
  return useQuery({
    ...(options || {}),
    gcTime: 1000 * 60 * 5,
    queryKey: useCustomerDeliveryOrdersByIdKey(customerId, pageable),
    queryFn: async () => {
      const params = new URLSearchParams();
      params.append('page', (pageable.page - 1).toString());
      params.append('size', pageable.size.toString());
      if ((pageable.sort ?? []).length > 0) {
        params.append('sort', (pageable.sort ?? []).join(',') as string);
      }
      const { data: response } = await apiGet<PaginationModel<DeliveryOrderMinimalReadDTO>>(`/order/delivery/by-customer/${customerId}/paged?${params.toString()}`);
      return response;
    },
    enabled: user?.id != null && !!customerId
  });
}
