import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { PublicRuntimeConfig } from '@orsys/config';

const { version,bugsnagApiKey, environment } = PublicRuntimeConfig

Bugsnag.start({
	apiKey: bugsnagApiKey!,
	releaseStage: environment,
	appVersion: version,
	plugins: [new BugsnagPluginReact(React)],
});

export default Bugsnag;
