import React, { Component } from 'react';
import { withRouter } from 'next/router';
import { UserDetailedReadDTO } from 'libs/common/src/models/UserModels';
import { apiGet } from './ApiUtils';
import { WithRouterProps } from 'next/dist/client/with-router';
import { RestaurantMinimalReadDTO } from 'libs/common/src/models/RestaurantModels';
import { RegistrationStepHeaderKey } from './GlobalConstant';
import { SystemRole } from 'libs/common/src/models/enum/SystemRole';
import { UrlObject } from 'url';
import { injectIntl } from 'react-intl';
import { WithIntlComponentProps } from './i18n';
import { parse } from 'next-useragent';
import { isAdminOrManager } from 'libs/common/src/utils/RoleUtils';
import { RestaurantServiceType } from 'libs/common/src/models/enum/RestaurantServiceType';

const STORAGE_KEY = 'context';

export interface OrsysContextProviderProps extends WithRouterProps, WithIntlComponentProps {}

export enum AlertType {
	'confirm',
	'alert',
}

interface AlertProps {
	title?: string;
	description?: string;
	type?: AlertType;
	confirmLabel?: string;
	cancelLabel?: string;
	onConfirm?: (confirm?: boolean) => void;
}

export interface BreadCrumbLinks {
	title?: string | {id: string};
	href: string | UrlObject;
	as?: string | UrlObject;
	disabled?: boolean;
	bold?: boolean;
}

export interface OrsysContextProviderState {
	user?: UserDetailedReadDTO | null;
	restaurant: RestaurantMinimalReadDTO | null;
	restaurantServiceType: RestaurantServiceType | null;
	restaurantList: RestaurantMinimalReadDTO[];
	stayLoggedIn: boolean;
	menuIsOpen: boolean;
	alert?: AlertProps;
	breadCrumbList: BreadCrumbLinks[];
}

export interface OrsysContextProviderValues extends OrsysContextProviderState {
	setMenuIsOpen: (open: boolean) => void;
	setUser: (user: UserDetailedReadDTO, headers?: Headers) => void;
	setStayLoggedIn: (stayLoggedIn: boolean) => void;
	loadRestaurants: () => Promise<void>;
	setRestaurant: (restaurant: RestaurantMinimalReadDTO | null) => void;
	clearUser: () => void;
	showAlert: (alertProps: AlertProps) => void;
	hideAlert: () => void;
	load: () => void;
	setBreadCrumbList: (list: BreadCrumbLinks[]) => void;
}

const initialState = {
	menuIsOpen: true,
	stayLoggedIn: false,
	restaurantList: [],
	restaurant: null,
	restaurantServiceType: null,
	breadCrumbList: [],
};

export const OrsysContext = React.createContext<OrsysContextProviderValues | any>({});

class OrsysContextProvider extends Component<OrsysContextProviderProps, OrsysContextProviderState> {
	constructor(props: OrsysContextProviderProps) {
		super(props);

		this.state = Object.assign({}, initialState);
	}

	loadingBreadcrumb = false;

	loaded = false;

	componentDidMount() {
		this.load();
		this.registerSessionClear();
	}

	componentDidUpdate() {
		this.registerSessionClear();
	}

	registerSessionClear = () => {
		// window.addEventListener('beforeunload', (event) => {
		//     const {stayLoggedIn} = this.state;
		//
		//     if (window.performance) {
		//         console.info("window.performance work's fine on this browser");
		//     }
		//     if (performance.navigation.type == 1) {
		//         console.info( "This page is reloaded" );
		//     } else {
		//         console.info( "This page is not reloaded");
		//         if (!stayLoggedIn) {
		//             event.preventDefault();
		//             try {
		//                 apiPost<void>("/auth/logout");
		//             } catch (e) {
		//
		//             }
		//             localStorage.clear();
		//         }
		//     }
		// });
	};

	load = async () => {
		const { router } = this.props;
		if (!router.pathname.startsWith('/auth')) {
			let savedState = localStorage.getItem(STORAGE_KEY);

			if (savedState && !this.loaded) {
				this.loaded = true;
				let savedSate = JSON.parse(savedState);

				try {
					const userAgent = parse(window.navigator.userAgent);
					if (!userAgent.isDesktop) {
						savedSate.menuIsOpen = false;
					}
				} catch (e) {}
				this.setState(
					{
						...savedSate,
					},
					() => {
						this.loadUser();
					},
				);
			} else {
				this.loadUser();
			}
		}
	};

	loadUser = async () => {
		const { router } = this.props;
		if (!router.pathname.includes('maps-proxy')) {
			try {
				const { data, headers } = await apiGet<UserDetailedReadDTO>('/user/current');
				this.setUser(data, headers);
			} catch (error) {
				console.log('ERROR', error);
				localStorage.clear();
				router.push('/auth');
			}
		}
	};

	setAndPersistState<K extends keyof OrsysContextProviderState>(
		state: Pick<OrsysContextProviderState, K>,
		callback?: () => void,
	) {
		this.setState(state, () => {
			localStorage.setItem(STORAGE_KEY, JSON.stringify(this.state));
			if (callback) {
				callback();
			}
		});
	}

	showAlert = (alertProps: AlertProps) => {
		this.setAndPersistState({
			alert: alertProps,
		});
	};

	setMenuIsOpen = (open: boolean) => {
		this.setAndPersistState({
			menuIsOpen: open,
		});
	};

	hideAlert = () => {
		this.setAndPersistState({
			alert: undefined,
		});
	};

	loadRestaurants = async () => {
		const { user, restaurant } = this.state;
		let restaurants = (await apiGet<RestaurantMinimalReadDTO[]>('/restaurant/allminimal')).data;

		let primaryRestaurant = restaurants[0];

		if (user?.restaurantList) {
			try {
				primaryRestaurant = restaurants.filter(
					({ id }) => user?.restaurantList.filter(({ primary }) => primary)[0].restaurantId === id,
				)[0];
			} catch (e) {}
		}

		const tempRestaurant = restaurant
			? restaurant
			: restaurants.length > 0 && !isAdminOrManager(user)
				? primaryRestaurant
				: null;

		await this.loadRestaurantServiceType(tempRestaurant?.id);
		this.setAndPersistState({
			restaurantList: restaurants,
			restaurant: tempRestaurant,
		});
    return restaurants;
	};

	loadRestaurantServiceType = async (restaurantId?: number) => {
		if (restaurantId) {
			const serviceTypeData = (
				await apiGet<RestaurantServiceType>(
					`/restaurant/${restaurantId}/setting/general_restaurant_service_type`,
				)
			).data;
			this.setAndPersistState({
				restaurantServiceType: serviceTypeData,
			});
		}
	};

	setRestaurant = async (restaurant: RestaurantMinimalReadDTO | null) => {
		const { router } = this.props;
		await this.loadRestaurantServiceType(restaurant?.id);
		this.setAndPersistState({ restaurant: restaurant }, () => {
			router.push('/');
		});
	};

	setStayLoggedIn = (stayLoggedIn: boolean) => {
		this.setAndPersistState({ stayLoggedIn: stayLoggedIn });
	};

	setUser = (user: UserDetailedReadDTO, headers?: Headers) => {
		this.setAndPersistState({ user: user }, () => {
			const { router } = this.props;
			if (user.role === SystemRole.PARTIAL_REG) {
				let step = null;
				if (headers) {
					step = headers.get(RegistrationStepHeaderKey);
				}
				router.push(`/auth/signup${step ? `/${step.toLowerCase()}` : ''}`);
			} else {
				if (!this.loaded) {
					this.loadRestaurants();
				}
				if (user != null && router.asPath.includes('auth')) {
					router.push('/');
				}
			}
		});
	};

	clearUser = () => {
		this.setAndPersistState(Object.assign({}, initialState), () => {
			localStorage.clear();
			window.location.reload();
		});
	};

	setBreadCrumbList = (list: BreadCrumbLinks[]) => {
		const { intl } = this.props;
		const { breadCrumbList } = this.state;
		if (!this.loadingBreadcrumb && JSON.stringify(breadCrumbList) !== JSON.stringify(list)) {
			this.loadingBreadcrumb = true;
			this.setState(
				{
					breadCrumbList: [
						{
							title: intl.formatMessage({ id: 'sidebar.dashboard' }),
							href: '/',
						},
						...list,
					],
				},
				() => {
					this.loadingBreadcrumb = false;
				},
			);
		}
	};

	render() {
		const {
			state,
			props: { children },
			setUser,
			load,
			clearUser,
			setRestaurant,
			setStayLoggedIn,
			showAlert,
			hideAlert,
			setBreadCrumbList,
			loadRestaurants,
			setMenuIsOpen,
		} = this;
		return (
			<OrsysContext.Provider
				value={{
					setUser,
					clearUser,
					setRestaurant,
					setStayLoggedIn,
					showAlert,
					hideAlert,
					setBreadCrumbList,
					setMenuIsOpen,
					loadRestaurants,
					load,
					...state,
				}}>
				{children}
			</OrsysContext.Provider>
		);
	}
}

export default withRouter<OrsysContextProviderProps>(injectIntl(OrsysContextProvider));

export const OrsysContextConsumer = OrsysContext.Consumer;

export const useOrsysContext = (): OrsysContextProviderValues => React.useContext(OrsysContext);
