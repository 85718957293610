import { useQuery } from '@tanstack/react-query';
import { useOrsysContext, apiGet } from '../../utils';
import type { UseQueryOptions } from '@tanstack/react-query/src/types';
import { WhitelabelAvailablePaymentTypeReadDto, WhitelabelRestaurantPaymentTypeReadDto } from '../../models/whitelabel';

export const whitelabelGlobalKey = ['whitelabel'];

export const whitelabelPaymentTypesByRestaurantKey = (restaurantId: number) => ['whitelabel', 'payment-types', restaurantId];

export function useWhitelabelPaymentTypesByRestaurantQuery(restaurantId: number, { options }: {
  options?: UseQueryOptions<WhitelabelRestaurantPaymentTypeReadDto[]>
} = {}) {
  const { user } = useOrsysContext();
  return useQuery({
    ...(options || {}),
    initialData: [],
    gcTime: 1000 * 60 * 5,
    queryKey: whitelabelPaymentTypesByRestaurantKey(restaurantId),
    queryFn: async () => {
      const { data: response } = await apiGet<WhitelabelRestaurantPaymentTypeReadDto[]>(`/whitelabel-admin/restaurant/${restaurantId}/payment-types`);
      return response;
    },
    enabled: user?.id != null && restaurantId != null
  });
}

export const whitelabelAvailablePaymentTypesKey =  ['whitelabel', 'available-payment-types'];

export function useWhitelabelAvailablePaymentTypesQuery(restaurantId: number, { options }: {
  options?: UseQueryOptions<WhitelabelAvailablePaymentTypeReadDto[]>
} = {}) {
  const { user } = useOrsysContext();
  return useQuery({
    ...(options || {}),
    initialData: [],
    gcTime: 1000 * 60 * 5,
    queryKey: whitelabelAvailablePaymentTypesKey,
    queryFn: async () => {
      const { data: response } = await apiGet<WhitelabelAvailablePaymentTypeReadDto[]>(`/whitelabel-admin/payment-types/available`);
      return response;
    },
    enabled: user?.id != null && restaurantId != null
  });
}
