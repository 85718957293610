export function convertFromJsonToWriteDto(allergens: any):string {
    let result: any[] = [];

    Object.assign([], allergens).forEach((data:any, index:number) => {(data?result.push(index):null)});

    return result.join();
}

export function convertFromDtoToJson(allergens: any):any {
    let result = {};

    for (let index = 1; index < 15; index++) {
        // @ts-ignore
        (allergens.find(data => data.id === index)?result[index] = true:result[index] = false)
    }

    return result;
}


