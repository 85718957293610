import { apiGet } from '../../utils/ApiUtils';
import { BankCard, CardDataType } from '@orsys/common';
import { useQuery } from '@tanstack/react-query';
import { useOrsysContext } from '../../utils/OrsysContext';
import type { UseQueryOptions } from '@tanstack/react-query/src/types';

export const cardGlobalKey = ['cards'];

export const cardByCurrentUserKey = ['cards', 'by-current-user'];

export function useCardByCurrentUserQuery({ options }: { options?: UseQueryOptions<BankCard[]> } = {}) {
	const { user } = useOrsysContext();
	return useQuery({
		...(options || {}),
		initialData: [],
		gcTime: 1000 * 60 * 5,
		queryKey: cardByCurrentUserKey,
		queryFn: async () => {
			const { data: response } = await apiGet<BankCard[]>('/card/by-user/current');
			return response.map(mapCardDefaults);
		},
		enabled: user?.id != null
	});
}

export const cardByCurrentRestaurantKey = (restaurantId?: string) => ['cards', 'by-restaurant', restaurantId];

export function useCardByRestaurantQuery(restaurantId?: string, { options }: {
	options?: UseQueryOptions<BankCard[]>
} = {}) {
	return useQuery({
		...(options || {}),
		initialData: [],
		gcTime: 1000 * 60 * 5,
		queryKey: cardByCurrentRestaurantKey(restaurantId),
		queryFn: async () => {
			const { data: response } = await apiGet<BankCard[]>(`/card/by-restaurant/${restaurantId}`);
			return response.map(mapCardDefaults);
		},
		enabled: !!restaurantId
	});
}

const mapCardDefaults = (card: BankCard) => ({
	...card,
	type: card.type ? card.type : CardDataType.Visa
});
